






































































import Vue, { PropType } from 'vue';
import { RawLocation } from 'vue-router';

import AssignSupportAccessModal from '@/components/shared/AssignSupportAccessModal.vue';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import { AccountDetails } from '@/models/accounts/account-details';
import { AccountProject } from '@/models/accounts/account-project';
import { RouteNames } from '@/models/config/route-name';
import { projectService } from '@/services/project.service';

export default Vue.extend({
  name: 'AccountProjects',
  components: {
    LoadingSpinner,
    AssignSupportAccessModal,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    accountDetails: {
      type: Object as PropType<AccountDetails>,
      default: null,
    },
    accountId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      assignProjectSupport: false,
      projectToAssignSupport: null as AccountProject | null,
    };
  },
  methods: {
    assignSupport(project: AccountProject) {
      this.assignProjectSupport = true;
      this.projectToAssignSupport = project;
    },
    async setManagement(project: AccountProject) {
      project.managed = !project.managed;
      await projectService
        .edit(project.projectId as string, project.managed)
        .then((response) => {
          if (response.status === 204) {
            const isManaged = project.managed;
            Vue.toasted.global.success(
              `Successfully set ${project.projectName} as ${
                isManaged ? 'a managed' : 'an unmanaged'
              } project.`,
            );
          }
        });
    },
    managementSetting(project: AccountProject) {
      if (project.managed) {
        return 'Set to Unmanaged';
      }
      return 'Set to Managed';
    },
    projectRoute(projectId: string): RawLocation {
      return {
        name: RouteNames.PROJECT_DETAILS,
        params: {
          projectId,
        },
      };
    },
  },
});
